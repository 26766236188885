<template>
  <div class="">
    <div class="page">
      <!-- 酒店列表 -->
      <div class="title">
        <i class="el-icon-office-building"> </i>
        <span>酒店列表</span>
      </div>
      <div v-if='list && list.length>0'>
        <div class="list">
          <div class="row"
               v-for='(item,index) in list'
               @click="goother({path:'/hotelDetail', query:{meetingId:meetingId,hotelId:item.id}})"
               :key='index'>
            <div class="left">
              <img :src="$global.regexImg(item.info_pic)"
                   alt="">
            </div>
            <div class="right">
              <div class="ellispTwo">{{item.name}}</div>
              <div>
                <el-rate v-model="item.star_num"
                         void-color='#fff'
                         disabled>
                </el-rate>
              </div>
              <div>
                <span>¥</span>
                <span>{{item.min_amount}}</span>
                <span>起</span>
              </div>
            </div>
          </div>
        </div>
        <div class='pagination'>
          <el-pagination style="margin-top:37px;"
                         background
                         layout="prev, pager, next"
                         :total="total"
                         :page-size="limit"
                         @current-change="changePage">
          </el-pagination>
        </div>
      </div>
      <temp-data v-else></temp-data>
    </div>
  </div>
</template>

<script>
import { get_hotel } from '@/utils/Api/meeting'
import tempData from '@/components/tempData'

export default {
  components: { tempData },
  props: [],
  data () {
    return {
      meetingId: null,
      page: 1,
      limit: 10,
      total: 0,
      list: [],
    }
  },
  methods: {
    async get_list () {
      let { total, data } = await get_hotel({ meet_id: this.meetingId, page: this.page, limit: this.limit })
      if (data) this.list = data
      this.total = total
    },
    changePage (newpage) {
      this.page = newpage
      this.get_list()
    },
  },
  created () {
    this.meetingId = this.$route.query.meetingId
    this.get_list()
  },
  mounted () { },
  destroyed () { },
  computed: {},
  watch: {}
}
</script>

<style lang="scss" scoped>
.page {
  width: 1200px;
  margin: 0 auto;
}
.pagination {
  margin: 20px 0;
}
.title {
  padding: 20px 0;
  text-align: left;
  color: #6c6c6c;
  line-height: 30px;
  span {
    margin-left: 10px;
  }
}
.list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .row {
    display: flex;
    justify-content: space-between;
    width: 590px;
    height: 175px;
    margin-right: 10px;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 16px;
    padding: 20px;
    box-sizing: border-box;
    &:nth-child(even) {
      margin-right: 0;
    }
    .left {
      width: 108px;
      height: 135px;
      overflow: hidden;
      border-radius: 10px;
      display: flex;
      align-items: center;
      img {
        &:hover {
          transform: scale(1.2);
          transition: all 0.3s;
        }
        max-width: 108px;
        max-height: 135px;
        border-radius: 10px;
      }
    }
    .right {
      width: calc(100% - 124px);
      text-align: left;
      > div:nth-child(1) {
        font-size: 18px;
        line-height: 24px;
        color: #030303;
        font-weight: 700;
      }
      > div:nth-child(2) {
        margin-bottom: 16px;
      }
      > div:nth-child(3) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 16px;
        color: #999;
        span:nth-child(1) {
          color: #ff5151;
          font-size: 12px;
        }
        span:nth-child(2) {
          font-weight: 700;
          color: #ff5151;
          font-size: 19px;
        }
      }
    }
  }
}
</style>
